<template>
   <v-slide-x-reverse-transition>
      <div v-if="selfItem != null" class="sideForm" :class="{ sticktop: scrolled }">
         <v-card>
            <v-card-title>
               {{ (!selfItem.id) ? 'Add a new Site' : 'Edit site' }}
            </v-card-title>
            <v-card>
               <v-icon class="closeBTN" @click="closeForm"> mdi-close </v-icon>
            </v-card>
         </v-card>
         <v-divider></v-divider>
         <v-form ref="form" v-model="valid" lazy-validation >
            <v-text-field
               v-model="selfItem.title"
               label="Site Name"
               color="primary"
               persistent-placeholder
               required
            >
            </v-text-field>
            <v-text-field
               v-model="selfItem.location"
               label="Site location"
               persistent-placeholder
               required
            ></v-text-field>

            <v-file-input accept="image/*"
                          label="Select image"
                          prepend-icon=""
                          append-icon="mdi-dots-horizontal"
                          v-model="tmpimg"
                          persistent-placeholder
                          @change="addFiles">
            </v-file-input>
            <v-card v-if="tmpimg || fileDat" outlined color="white" class="pa-1 mt-0 mb-5">
               <v-row justify="left" no-gutters>
                  <v-col cols="7">
                     <v-img class="pa-1"
                            :src="fileDat" ref="file"
                     ></v-img>
                  </v-col>
                  <v-col cols="4" class="mr-0 ml-2" >
                     <!-- no need to open file here 
                     <a href="#" class="caption text-decoration-none" @click="editItem(item)" >
                        <v-icon color="primary" x-small >
                           mdi-open-in-new
                        </v-icon>
                        Open
                     </a>
                     <br/>
                     -->
                     
                     <a href="#" class="caption text-decoration-none"  @click="removeFile" >
                        <v-icon color="primary" x-small >
                           mdi-close-circle-outline
                        </v-icon>
                        Remove
                     </a>
                  </v-col>
               </v-row>
            </v-card>
            <!-- <v-file-input v-model="logo" label="Company logo"></v-file-input> -->

            <v-textarea
               v-model="selfItem.short_desc"
               label="Short Description"
               auto-grow
               required
               persistent-placeholder
               rows="1"
            ></v-textarea>

            <v-card class="formFooterButtons">
               <v-btn :disabled="!valid" color="info" @click="submit">Apply
                  <v-icon
                     right
                  >
                     mdi-check-bold
                  </v-icon>
               </v-btn>
            </v-card>
         </v-form>

      </div>
   </v-slide-x-reverse-transition>
</template>
<script>
import SiteService from '@/service/SiteService.js';
import Site from '@/models/Site.js';
import Util from '@/utils';

export default {
   props: {
      item: {
         type: Object,
         default: null,
      },
   },
   watch: {
      item: function (val) {
         if (val == null) {
            this.selfItem = null;
         } else {
            this.selfItem = { ...val };
            if (val) { this.fileDat = val.image; }
         }
      },
   },
   data: () => ({
      fileDat: null,
      tmpimg: null,
      selfItem: null,
      valid: false,
      email: '',
      expDate: '',
      usersCount: '',
      logo: '',
      address: '',
      name: '',
      nameRules: [
         (v) => !!v || 'This field is required',
         (v) => v.length <= 50 || 'Name must be less than 20 characters',
      ],
      emailRules: [
         (v) => !!v || 'This field is required',
         (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      usersCountRules: [
         (v) => !!v || 'This field is required',
         (v) => v >= 1 || 'This field must ne a number',
      ],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      scrolled: false,
   }),
   methods: {
      addFiles() {
         this.readers = new FileReader();
         this.readers.onloadend = (e) => {
            const fileData = this.readers.result;
            const imgRef = this.$refs.file;
            // imgRef.src = fileData
            this.fileDat = fileData;
            // console.log(fileData);
            this.selfItem.image = fileData;
            // send to server here...
         };

         this.readers.readAsDataURL(this.tmpimg);
         // })
      },
      removeFile() {
         this.fileDat = null;
         this.tmpimg = null;
         this.selfItem.image = null;
      },
      submit() {
         if (this.$refs.form.validate()) {
            const siteService = new SiteService(this);
            if (!this.selfItem.id) {
               // is this a new item?
               const site = new Site(this.selfItem);
               siteService.add(site).then((res) => {
                  if (res) {
                     //this.$mixpanel.track('Site added',{id: this.selfItem.id, name: this.selfItem.title});
                     Util.sendEvent('Site', 'added',this.selfItem.title, this.selfItem.id);
                     this.closeForm();
                     this.$store.dispatch('alertUser', 'Site added successfuly');
                  } else {
                     alert('else ...');
                  }
               });
            } else {
               siteService.update(this.selfItem.id, this.selfItem).then((res) => {
                  if (res) {
                     //this.$mixpanel.track('Site update', {id: this.selfItem.id, name: this.selfItem.title});
                     Util.sendEvent('Site', 'updated', this.selfItem.title, this.selfItem.id);
                     this.closeForm();
                     this.$store.dispatch('alertUser', 'Site updated successfuly');
                  } else {
                     alert('else ...');
                  }
               });
            }
         }
      },
      clear() {
         this.$refs.form.reset();
      },
      closeForm() {
         this.selfItem = null;
         this.fileDat = null;
         this.tmpimg = null;
         this.$emit('update-value', null);
      },
      scrollListener: function () {
         if (window.scrollY > 65) { this.scrolled = true; } else { this.scrolled = false; }
      },
   },
   mounted: function () {
      window.addEventListener('scroll', this.scrollListener);
   },
   beforeDestroy: function () {
      window.removeEventListener('scroll', this.scrollListener);
   },
};
</script>
