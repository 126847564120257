import ParentModel from '@/models/parent/ParentModel.js';

export default class Site extends ParentModel {
   constructor(data) {
      super(data);
      if (data != null) {
         this.id = data.id;
         this.company_id = data.company_id;
         this.title = data.title;
         this.short_desc = data.short_desc;
         this.location = data.location;
         this.image = data.image;
         this.is_active = data.is_active;
      } else {
         this.id = '';
         this.company_id = '';
         this.title = '';
         this.short_desc = '';
         this.location = '';
         this.image = '';
         this.is_active = '1';
      }
   }

   get toDto() {
      const dto = {
         id: this.id,
         company_id: this.company_id,
         title: this.title,
         short_desc: this.short_desc,
         location: this.location,
         image: this.image,
         is_active: this.is_active,
      };
      return dto;
   }
}
