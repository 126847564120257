<template>
   <div
      class="px-5 app-container v-sheet v-sheet--shaped mt-4" >
      <v-btn color="primary" v-if="(auth.role!='viewer')" class="ma-4 mb-8 ml-0 pl-0" width="200" x-large @click="createItem">
         <span class="text-caption text-md-left font-weight-medium">
            Add a new site
         </span>
         <v-icon class="pl-16" right>
            mdi-plus-circle-outline
         </v-icon>
      </v-btn>
      <v-card
         class="d-flex align-content-start flex-wrap"
         color="transparent"
         flat
         tile
         style="overflow-y: auto;
         height: calc(100vh - 185px);
         "
      >
         <v-card v-for="n in list"
                 :key="`${n.id}siteList`" class="rounded mr-10 mb-10" light elevation="2" width="420" >
            <v-img
               :src="n.image"
               height="200"
               @click="view(n.id)"
               style="cursor: pointer"
            ></v-img>
            <v-card-title class="light-blue--text text--darken-4">
               {{n.title}}
            </v-card-title>
            <v-card-text >
               <v-row
                  align="center"
                  class="mx-0"
               >
                  <span class="text-caption mt-2">
                     location: {{n.location}}
                     <br/>
                     {{ n.short_desc || '&nbsp;' }}
                  </span>
               </v-row>
            </v-card-text>
            <!--
                     <v-switch
                        class="pl-3"
                        v-model="n.is_active"
                        :label="`${togtitle(n, n.is_active)}`"
                        @click="changeActiveStatus(n)"
                     ></v-switch>
                     -->
            <v-card-actions>
               <v-btn
                  color="secondary"
                  text
                  plain
                  v-if="(auth.role!='viewer')"
                  @click="editSite(n)"
               >
                  <v-icon
                     class="mr-0"
                     left
                     x-small
                  >
                     mdi-pencil-outline
                  </v-icon>
                  Edit
               </v-btn>
               <v-btn color="secondary" text plain @click="view(n.id)">
                  <v-icon class="mr-0" left small>
                     mdi-chevron-right
                  </v-icon>
                  Open
               </v-btn>
            </v-card-actions>
         </v-card>
         
      </v-card>

      <SiteForm
         :item="item"
         @update-value="updateData"
      />

      <!-- <SystemView /> -->
   </div>
</template>

<script>
import { auth } from '@/main';
import Site from '@/models/Site.js';
import SiteService from '@/service/SiteService.js';
import SiteForm from '@/components/SiteForm.vue';
import Util from '@/utils';

export default {
   components: {
      SiteForm,
   },
   data() {
      return {
         auth: auth,
         siteService: new SiteService(),
         show: false,
         total: 0,
         list: [],
         item: null,
      };
   },
   created() {
      this.doReset();
   },
   mounted() {
      this.$mixpanel.identify(auth.user.id);
      this.$mixpanel.people.set_once({
         email: auth.user.email,
         name: `${auth.user.first_name} ${auth.user.last_name}`,
         created: new Date(),
      });
      this.$mixpanel.people.set({
         'First Name': auth.user.first_name,
         'Last Name': auth.user.last_name,
         'Company id': auth.user.company_id,
         'Company name': (auth.user.company) ? auth.user.company.name : '',
         Role: auth.user.role,
         Date: new Date(),
      });
   },
   methods: {
      view(id) {
         Util.loading(true);
         this.siteService.get(id).then((res) => {
            if (typeof (res.data.id) !== 'number') {
               Util.showMessage("Sorry, i couldn't find the specific site", 'error');
               Util.loading(false);
               //this.$mixpanel.track('Open site', { id: res.data.id, name: res.data.title, status: 'error' });
               Util.sendEvent('Site', 'Open', 'error', res.data.id);
               return;
            }
            this.$store.dispatch('setSite', new Site(res.data));
            Util.loading(false);
            // this.$mixpanel.track('Site opened', { id: res.data.id, name: res.data.title, status: 'success' });
            // this.$gtag.event('Site opened', {
            //    event_category: 'Site',
            //    event_label: res.data.title,
            //    value: res.data.id,
            // });
            Util.sendEvent('Site', 'Opened', res.data.title, res.data.id);
            this.$router.push({ name: 'main' });
         });
      },
      updateData(data) {
         this.item = data;
         this.doReset();
      },
      editSite(sitedata) {
         this.item = sitedata;
         //this.$mixpanel.track('Site edit clicked', { id: sitedata.id, name: sitedata.title });
         Util.sendEvent('Site', 'Edit', sitedata.title, sitedata.id);
         /// /console.log(sitedata)
      },
      createItem() {
         //this.$mixpanel.track('Site add clicked');
         Util.sendEvent('Site', 'Add', 'clicked');
         this.item = new Site();
      },
      togtitle(n, stat) {
         if (stat) {
            return 'active';
         }
         return 'inactive';
      },
      changeActiveStatus(n) {
         this.siteService.update(n.id, n);
      },
      clicksite(siteid) {
         alert(siteid);
      },
      callServiceList(page = 1) {
         this.page = page;
         this.loading = true;
         this.siteService.list().then((list) => {
            if (list) {
               this.total = list.data.length;
               this.list = list.data;
            }
            this.loading = false;
         });
      },
      doSearch() {
         this.callServiceList(1);
      },
      doReset() {
         this.$store.dispatch('resetApp');
         this.callServiceList();
      },

   },
};
</script>

<style scoped>

.editbutton{
  position: absolute;
  top: 8px;
  color: rgb(207, 207, 207);
  right: 5px;
}

</style>
